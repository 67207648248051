<template>
  <div class="contact-root-container">
    <div class="page-title">通讯录</div>
    <div class="contact-main-container">
      <div class="contact-left-container">
        <div style="overflow-y: scroll">
          <div
            v-for="(memberItem, index) in memberMap"
            :key="index"
            style="margin-bottom: 30px"
          >
            <div
              style="font-size: 23px; font-weight: bold; color: #3c3c3c"
              :id="index"
            >
              {{ index }}
            </div>
            <div class="contact-list-container">
              <contact-item
                style="margin: 10px"
                v-for="item in memberItem"
                :key="item.client_user_id"
                :data-source="item"
                @click.native="showDetail(item.client_user_id)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="contact-right-container">
        <a
          v-for="(item, index) in keyList"
          class="contact-head-item"
          :href="'#' + item"
          :key="index"
          >{{ item }}</a
        >
      </div>
    </div>
    <contact-detail-modal
      ref="contactDetailModal"
      @show-asset-modal="showAssetModal"
    ></contact-detail-modal>
    <asset-detail-modal ref="assetDetailModal"></asset-detail-modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as pyHelper from "../../utils/pyHelper";
import ContactItem from "./Item/ContactItem";
import ContactDetailModal from "./Modal/ContactDetailModal";
import AssetDetailModal from "../Asset/Modal/AssetDetailModal";
export default {
  components: {
    "contact-item": ContactItem,
    "asset-detail-modal": AssetDetailModal,
    "contact-detail-modal": ContactDetailModal,
  },
  data() {
    return {
      keyList: [],
      memberMap: {},
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    ...mapActions({
      contactGetListAction: "contactGetList",
    }),
    getList() {
      this.contactGetListAction({
        employer_id: localStorage.getItem("employer_id"),
      })
        .then((res) => {
          this.userList = res;

          let tempMap = {};
          for (let item of this.userList) {
            if (!item.client_user_name) {
              item.client_user_name = "-";
            }
            let firstPY = pyHelper.makePy(item.client_user_name[0])[0];
            if (this.keyList.indexOf(firstPY) == -1) {
              this.keyList.push(firstPY);
              tempMap[firstPY] = [item];
            } else {
              tempMap[firstPY].push(item);
            }
          }
          this.keyList.sort();
          for (let item of this.keyList) {
            this.memberMap[item] = tempMap[item];
          }
        })
        .catch((error) => {
          console.error(error);
          this.$Message.error(error);
        });
    },
    showDetail(id) {
      this.$refs.contactDetailModal.open(id);
    },
    showAssetModal(id) {
      this.$refs.assetDetailModal.open(id);
    },
  },
};
</script>

<style scoped>
.contact-root-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-left: 15px;
  background-color: #f9f9f9;
  overflow: hidden;
}
.contact-main-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}
.contact-left-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.contact-right-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  background: #2a2828;
  color: #ffffff;
  border-radius: 15px;
  padding: 0px 10px;
  margin-right: 40px;
  margin-bottom: 20px;
}
.contact-head-item {
  cursor: pointer;
  color: #ffffff;
  transition: all 0.2s ease;
}
.contact-head-item:hover {
  transform: scale(1.5);
}
.contact-list-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>